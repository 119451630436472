.feedbackContainer{
  /* Always display the button in front of everything and not behind */
  z-index: 300;
  position: fixed;

  max-width: none;
  left: 1%;
  height: 6%;
  bottom: 1vh;

  -webkit-animation: slide-in-left 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-in-left 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.devisContainer{
  margin-right: auto;
  margin-left: auto;
}
#formSpace{
  margin-top: 12%;
}