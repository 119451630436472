.portfolioSubContainer{
    display: inline-block;
    width: 100%;
}

div.portfolioBackground {
    /* display: inline-block; // disabled due to space between pictures */
    overflow: hidden;

    width: 100%;
    max-width: 100%;

    /* To avoid blue tint due to homepage overall color background */
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;
}
#portfolioImgCJ {
    /* Text over image */
    position: relative;
    cursor: pointer;

    background-image: 
        linear-gradient( rgba(0,0,0,.4), rgba(0,0,0,.4) ),
        url('../media/portfolio/C&J/c&jbanner.jpg');

    width: 100%;
    height: 25vh;
    max-height: 25vh;
}
#portfolioImgR2 {
    /* Text over image */
    position: relative;
    cursor: pointer;

    background-image: 
        linear-gradient( rgba(0,0,0,.4), rgba(0,0,0,.4) ),
        url('../media/portfolio/D&B/d&bbanner.jpg');

    width: 100%;
    height: 25vh;
    max-height: 25vh;
}
#portfolioImgDB {
    /* Text over image */
    position: relative;
    cursor: pointer;

    background-image: 
        linear-gradient( rgba(0,0,0,.4), rgba(0,0,0,.4) ),
        url('../media/portfolio/R2/r2banner.jpg');

    width: 100%;
    height: 25vh;
    max-height: 25vh;
}
#portfolioImgBarberousse {
    /* Text over image */
    position: relative;
    cursor: pointer;

    background-image: 
        linear-gradient( rgba(0,0,0,.4), rgba(0,0,0,.4) ),
        url('../media/portfolio/barberousse/barberoussebanner.jpg');

    width: 100%;
    height: 25vh;
    max-height: 25vh;
}
#portfolioTxtOverImg{
    /* Text over image */
    position: absolute;
    top: 30%;
    left: 5%;

    /* Text style */
    color: white;
    font-weight: 500;
}

/* Animations */

div.portfolioBackground > div {
    width: 100%;
    height: 100%;

    background-size: 100%;
    background-position: center center;

    /* transition: background-size 2s ease; */
    transition: all 2s ease;
    -webkit-transition: all 2s ease;
}
div.portfolioBackground:hover > div {
    background-size: 120%;
}
.customCard .card{
    margin-bottom: 1vh;
}
.customCard .card:hover{
    margin-bottom: 4vh !important;
}





.p-sidebar-bottom .p-sidebar{
    display: inline-block !important;
    min-height: 55vh !important;
    background-color: rgba(255,255,255,0.8);
    // overflow-y: scroll;
}
.p-sidebar .p-sidebar-content{
    padding: 0rem !important;
}
.p-sidebar{
    -webkit-box-shadow: 0px -4px 29px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px -4px 29px rgba(50, 50, 50, 0.75);
    box-shadow: 0px -4px 29px rgba(50, 50, 50, 0.75);
}
.p-carousel{
    // width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.portfolioProjectContainer {
	padding: 2em;
	margin: auto;
}
.portfolioProjectCard {
    text-align: left;
    // border: 2px solid #d3d3d3;
    border-radius: 0.5em;
	.portfolioProjectContainer {
		padding: 0;
		min-width: 300px;
	}
}
.portfolioProjectSplitContent {
	justify-content: space-between;
	min-width: 50%;
}
.portfolioProjectSplitCol {
	flex: 1 2 0;
	max-width: 100%;
	&.col-auto {
		position: relative;
		padding-left:15px;
		padding-right: 15px;
	}
}
.portfolioProjectRow {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -15px 0 -15px;
    margin-right: 1%;
    margin-left: 1%;
}
.portfolioProjectCardSect {
	align-content: center;
	
	justify-content: center;
	margin: 10px;
	min-width: 180px;
	display: flex;
	flex-wrap: wrap;
}
.portfolioProjectItem {
	& > .portfolioProjectHead {
		text-align: center;
		margin-bottom: 3%;			
		.portfolioProjectTitle{
            font-family: 'Pacifico', cursive;
            text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
            font-size: 2vw;
			margin-bottom: 10px;
		} 
		.icon {
			margin-bottom: 10px;
		}
	}
}
@media (max-width: 991.98px) {
	.portfolioProjectTitle {
		text-align: center;
	}
	.portfolioProjectCardSect {
		 justify-content: center;
		min-height: 100px;
	}
    #dividerWrap {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 90%;
		
    }
	.portfolioProjectItem {
		display: block;
		min-width: 300;
		
	}
    .contentDivider {
		margin: 15px  0 15px 0;
		font-size: 20px;
        .dividedText {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            &:before,
            &:after {
              content: '';
              border-top: 2px solid;
              margin: 0 20px 0 0;
              flex: 1 0 20px;
            }
            &:after {
              margin: 0 0 0 20px;
            }
        }
    }
}
@media (min-width: 992px) {
	#dividerWrap {
    	flex-basis: 0;
    	flex-grow: 1;
    	max-width: 20px;
		padding: -10px 0 -10px;
		justify-content: center;
	}
	.split {
		justify-content: center;
	}
    .contentDivider {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
		justify-self: center;

        height: 100%;
        min-width: 100%;

        .dividedText {
            &:before,
            &:after {
                position: absolute;
                content: "";
                width: 1px;
                left: 50%;
                border-left: 2px solid black;
            }
            &:before {
                bottom: 50%;
                top: 0;
                margin-bottom: 20px;
            }
            &:after {
                top: 50%;
                bottom: 0;
                margin-top: 20px;
            }
        }
    }
}

.portfolioBannerCJ{
    width: 100%;
    background: #f47db0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f47db0, #ffa3ed, #f47db0);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f47db0, #ffa3ed, #f47db0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.portfolioContent {
    padding-top: 1.5%;
    display: inline-block;
    display: flex;
    max-height: 30vh;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
}
#portfolioContentImg{
    height: 90%;
    opacity: 1;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;
}
#portfolioContentImg:hover{
    transform: scale(1.175); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
#portfolioLeft{
    width: 47%;
    margin-right: 3%;
}
#portfolioRight {
    width: 50%;
}
@media screen and (max-width: 500px) {
    .portfolioContent{
        display: block;
        width: 80%;
    }
    #portfolioLeft {
      width: 100%;
      margin-bottom: 5%;
    }
    .portfolioRight {
        width: 100%;
    }

    .p-carousel{
        width: 80% !important;
        height: auto !important;
    }

    .card__title{
        font-size: 4.5vw !important;
    }

    .cards, .launchPack{
        width: 75% !important;
        margin-top: 2vh !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .launchPack{
        width: 85% !important;
    }
    .customCardDescription{
        font-size: 3vw !important;
    }
    .customCard .card:hover{
        margin-bottom: 3vh !important;
    }
}
