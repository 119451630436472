@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

/*
==============
Fundamentals
==============
*/
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* Hero (10) */
    font-style: normal;
}

html{
    scroll-behavior: smooth;
    background-color: rgba(253, 253, 253, 1);
}  

body{
    margin: 0;
}

/*
==============
Custom scrollbar
==============
*/
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #FFB572;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #F59A48;
}
.lock-scroll{
    overflow: hidden;
}

/*
==============
Text
==============
*/
/* Pacifico font family */
.pacificoFont{
    font-family: 'Pacifico', cursive;
    font-style: normal;
}

/* Hx Tags */
h1{
    font-family: 'Pacifico', cursive;
    font-style: normal;
        
    color: #FFB572;
    text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
}
h2{
    font-family: 'Pacifico', cursive;
    font-style: normal;
        
    color: white;
}
h5{
    color: white;
}
#customh1{
    font-size: 4vw;
}
.subTitleWrapper{
    display: inline-block;
    text-align: center;  
    width: 100%;
}
/* Colors */
#orangeText{
    color: #FFB572;
}
/* Shadows */
#textShadows{
    text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
}
/* Disable underline texts for any links */
Link{
    text-decoration: none;
}
.spanLink{
    text-decoration: none;
}
.spanLink:hover{
    text-decoration: none;
    cursor: pointer;
}
.spanLink{
    text-decoration: none;
}
.spanLink:hover{
    text-decoration: none;
    cursor: pointer;
}
a{
    text-decoration: none;
    cursor: pointer;
}
#disableTextDecoration{
    text-decoration: none;
    cursor: pointer;
    color: #EA7B19;
}
/* Colors only */
#formLabelColor{
    color: #495057 !important;
}
#formInputColor{
    color: #6c757d !important;
}
#formWhiteInputColor{
    color: white !important;
}

/*
======
Medias
======
*/
#imgPortfolioFeatures{
    opacity: 100% !important;
    transition: transform .4s; /* Animation */
    width: auto;
    max-height: 30vh;
}
#imgPortfolioFeatures:hover{
    transform: scale(1.175); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
#imgFeatures{
    opacity: 100% !important;
    transition: transform .4s; /* Animation */
}
#imgFeatures:hover{
    transform: scale(1.175); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
video{
    clip-path: inset(3px 3px); /* Delete small borders: increase proportionnaly inset depending to width percentage */
    width: 80%;
}

/* 
===============
Inputs & labels
===============
*/
.field{
    margin-top: 5%;
}
.customLabel{
    margin-bottom: 5%;
    margin-top: 1%;
}
.p-dialog .p-dialog-header{
  padding: 0.5rem !important;
}
.p-float-label{
  width: 15vw;
}

/*
===========
Theme edits
===========
*/
.p-button{
    background: #FFB572;
}
.p-button:hover{
    background: #F59A48 !important;
}
.p-inputtext{
    background: transparent;
}
.p-float-label > label{
    color: white;
}
.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix{
    color: white;
}
.p-editor-container .p-editor-content .ql-editor{
    background: transparent;
    color: white;
}
.p-scrollpanel, .scrollablePortfolio {
    margin-right: auto;
    margin-left: auto;
}
.p-scrollpanel .p-scrollpanel-bar{
    background: #FFB572 !important;
}
.p-scrollpanel .p-scrollpanel-bar:hover{
    background: #F59A48 !important;
}
#customBtnWidth{
    width: 50%;
}
.card{
    border: none !important;
}
.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next{
    color: white;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover{
    color: black;
}
.p-carousel-prev .pi, .p-carousel-next .pi{
    font-size: 25px;
}
.p-carousel-items-content{
    box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;
    display: inline-block;
}
.p-carousel .p-component, .p-component * {
    text-align: center;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background-color: #FFB572;
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active{
    color: #EA7B19 !important;
}
.p-tabview .p-tabview-panels{
    background-color: transparent !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-color: #FFB572 !important;
    color: #FFB572;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
    box-shadow: inset 0 0 0 0.2rem #FFB572;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    background: none !important;
    color: white;
    
    border: 2px solid rgba(20, 115, 146, 0.85);
}
.p-tabview .p-tabview-nav{
    background-color: transparent !important;
    border: 1px solid transparent !important;
}
.p-tabview-nav{
    justify-content: center;
}
.p-tabview .p-tabview-nav li{
    margin-right: 3% !important;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link{
    border-color: #FFB572 !important;
    color: #FFB572 !important;
}
.p-carousel-next, .p-carousel-prev{
    background-color: transparent !important;
}

/*
==============
Responsiveness
==============
*/
/* The text size can be set with a vw unit, which means the "viewport width".
That way the text size will follow the size of the browser window: */
* {
    font-size: 1.2vw;
}
/* Images scales both up and down on responsiveness and width restricted to a maximum size of 1920px */
img{
    width: 100%;
    max-width: 1920px;
    height: auto;
}
@media screen and (max-width: 500px) {
    *{
        font-size: 3vw !important;
    }
    #h1portfolio, #h1prestations, #h1contact{
        font-size: 10vw !important;
    }
    .prestationSubTitle, .prestationSubTitleRight, .prestationSubTitleCenter{
        font-size: 8vw !important;
    }
    #subTitlePortfolio, #subtitlePrestations, #subtitleContact{
        font-size: 3.5vw !important;
    }
    #responsiveTitleLogos{
        width: 13% !important;
    }
    .responsiveNavLogo{
        width: 25% !important;
    }
    .navbar-light .navbar-toggler{
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .navbarHomeLink{
        margin-right: 0px !important;
    }
    .navbarNavLogos{
        margin-right: auto !important;
        margin-left: auto !important;
        width: 10% !important;
    }
    .bannerBackground{
        height: 60vh !important;
    }
    .bannerItemTitleContainer{
        width: 100% !important;
    }
    .bannerItemTitleContent{
        font-size: 40px !important;
    }
    .bannerItemDescription{
        margin-top: 15% !important;
        width: 170% !important;
    }
    .portfolioProjectCard .portfolioProjectContainer{
        margin-bottom: 25% !important;
    }
    .portfolioProjectItem > .portfolioProjectHead .portfolioProjectTitle{
        font-size: 6vw !important;
    }
    #prestationsRight{
        width: 100% !important;
        padding-bottom: 20% !important;
    }
    .info h2, .contact h2{
        font-size: 5vw !important;
    }
}

/*
==============
Footer
==============
*/
#footerStickyBottom{
    bottom: 0;
    width: 100%;
    position: fixed;
}