.dashboardMainContainer{
    position: relative;

    width: 100%;
    height: 91vh;
}
#dashboardBackground{
    position: absolute;

    width: 100%;
    height: 100%;

    opacity: 0.275;
    z-index: -1;
}
.dashboardContainer{
    position: relative;

    height: 88%;
    width: 90%;
    
    padding: 1%;
    margin-right: auto;
    margin-left: auto;

    z-index: 1;
}
.dashboardLeftContainer {
	height: 100%;
	width: 70%;

	float: left;
}
.dashboardRightContainer {
	height: 100%;
	width: 27%;

	float: right;
}
.top, .middle{
    height: 15%;
    width: 100%;

    border-radius: 17px;
}
.top{
    background: rgba(79, 175, 165, 0.85);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, rgba(15, 145, 132, 0.85), rgba(15, 145, 132, 0.85), rgba(79, 175, 165, 0.85));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, rgba(15, 145, 132, 0.85), rgba(15, 145, 132, 0.85), rgba(79, 175, 165, 0.85)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px;
    
    float: top;
}
.middle{
    background-color:rgba(255, 255, 255, 0.385);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px;
    
    margin-top: 2%;
}
.bottom{
    height: 65%;
    width: 100%;

    background-color:rgba(255, 255, 255, 0.385);
    border: 1px solid rgba(79, 175, 165, 0.85);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px;

    border-radius: 17px;
    
    margin-top: 2%;
    float: bottom;
}
.dashboardUserInfo{
    height: 38%;
    width: 100%;

    background-color:rgba(255, 255, 255, 0.385);
    border: 1px solid rgba(79, 175, 165, 0.85);
    border-radius: 17px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px;
    
    float: top;
}
.dashboardAllProjects{
    height: 58%;
    width: 100%;

    background: rgba(79, 175, 165, 0.85);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, rgba(15, 145, 132, 0.85), rgba(15, 145, 132, 0.85), rgba(79, 175, 165, 0.85));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, rgba(15, 145, 132, 0.85), rgba(15, 145, 132, 0.85), rgba(79, 175, 165, 0.85)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px; 

    border-radius: 17px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 20px 0px;
    
    margin-top: 8%;
    float: bottom;
}