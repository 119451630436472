/* ========= */
/* Dividers */
/* ======== */

.colour-block {
    background:#FFB572;
    color:#03455B;
}
.white-block {
    background:#03455B;
    color:#FFB572;
}
.skew-c{
    width:100%;
    height:100px;
    position:absolute;
    left:0px;
    background: linear-gradient(to left bottom, #03455B 49%, #FFB572 50%);
}
.skew-cc{
    width:100%;
    height:100px;
    position:absolute;
    left:0px;
    background: linear-gradient(to right bottom, #FFB572 49%, #03455B 50%),    linear-gradient(-50deg, #03455B 16px, #000 0);
}


/* =============== */
/* Containers & h1 */
/* =============== */
.mainContainer{
    /* Height fits content */
    display: inline-block;
    width: 100%;
    background-color: #03455B;
    // background: #147392;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(132deg, #03455B, #147392, #03455B);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(132deg, #03455B, #147392, #03455B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // background-color: #03455B;
}
.videoContainer{
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: left;

    background-color: white !important;

    /* -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);     */
}
#resizeVideoBanner{
    width: 90vw;
    margin-left: 3%;
}
.presentationContainer{
    /* Height fits content */
    display: inline-block;
    width: 100%;
    box-shadow: rgb(0 0 0 / 18%) 0px 7px 29px 0px;
}
#h1presentation{
    font-size: 4vw;
    font-family: 'Pacifico', cursive;
    font-style: normal;
    color: #EA7B19;
    text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
}
#subTitlePresentation{
    font-size: 0.85vw;
    font-style: normal;
    color: #EA7B19;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    
    &::before,
    &::after {
      content: '';
      display: block;
      height: 0.09em;
      min-width: 15vw;
    }
    
    &::before {
      background: linear-gradient(to right, rgba(240,240,240,0), #fff);
      margin-right: 4vh;
    }
    
    &::after {
      background: linear-gradient(to left, rgba(240,240,240,0), #fff);
      margin-left: 4vh;
    } 
}
.portfolioContainer{
    /* Height fits content */
    display: inline-block;
    width: 100%;
}
#h1portfolio{
    font-size: 4vw;
    color: #4FAFA5;
}
#subTitlePortfolio{
    font-size: 1vw;
    font-style: normal;
    color: #4FAFA5;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    
    &::before,
    &::after {
      content: '';
      display: block;
      height: 0.09em;
      min-width: 15vw;
    }
    
    &::before {
      background: linear-gradient(to right, rgba(240,240,240,0), #4FAFA5);
      margin-right: 4vh;
    }
    
    &::after {
      background: linear-gradient(to left, rgba(240,240,240,0), #4FAFA5);
      margin-left: 4vh;
    } 
}
.prestationsContainer{
    /* Height fits content */
    display: inline-block;
    width: 99%;
    padding-bottom: 10vh;
}
#h1portfolioCJ{
    font-size: 4vw;
    color: #f47db0;
}
#subTitlePortfolioCJ{
    font-size: 0.85vw;
    font-style: normal;
    color: #f47db0;
    text-transform: uppercase;
}
#h1prestations{
    font-size: 4vw;
    color: #317F99;
}
#subTitlePrestations{
    font-size: 1vw;
    font-style: normal;
    color: #317F99;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    
    &::before,
    &::after {
      content: '';
      display: block;
      height: 0.09em;
      min-width: 15vw;
    }
    
    &::before {
      background: linear-gradient(to right, rgba(240,240,240,0), #317F99);
      margin-right: 4vh;
    }
    
    &::after {
      background: linear-gradient(to left, rgba(240,240,240,0), #317F99);
      margin-left: 4vh;
    } 
}
.contactContainer{
    /* Height fits content */
    display: inline-block;
    width: 100%;

    margin-bottom: 3%;
}
#h1contact{
    font-size: 4vw;
    color: #FFB572;
}
#subTitleContact{
    font-size: 1vw;
    font-style: normal;
    color: #FFB572;
    text-transform: uppercase;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    &::before,
    &::after {
      content: '';
      display: block;
      height: 0.09em;
      min-width: 15vw;
    }
    
    &::before {
      background: linear-gradient(to right, rgba(240,240,240,0), #FFB572);
      margin-right: 4vh;
    }
    
    &::after {
      background: linear-gradient(to left, rgba(240,240,240,0), #FFB572);
      margin-left: 4vh;
    } 
}


/* ====== */
/* Banner */
/* ====== */
.bannerContainer {
    display: flex;
    justify-content: center;
    text-align: center;
}
.bannerItem {
    flex-basis: 50%;
    height: 80vh;
    margin: 15px;

    margin-top: 7%;
}
.bannerBackground {
    background: linear-gradient(132deg, #03455B, #147392, #5399B0);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    position: relative;
    height: 110vh;
    width: 100%;
    overflow: hidden;
    padding:0;
    margin:0px;
}
.bannerItemTitleContainer{
    display: flex;
    width: 63%;

    padding-top: 3%;
    background: #fff;

    margin-top: 20%;
    margin-left: 15%;
    
    -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15) !important;
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15) !important;  
}
.bannerItemTitleContent{
    font-size: 80px;
    font-weight: bold;

    background: #4FAFA5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #4FAFA5, #317F99);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(132deg, #03455B, #4FAFA5, #317F99);; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-transform: uppercase;
    line-height: .88;
}
.bannerItemDescription{
    display: inline-block;
    width: 90%;

    color: white;
    text-align: justify !important;
    margin-top: 5%;
    margin-left: 15%;
}
.bannerPicture{
    width: 60%;
    margin-top: 23%;
    border-radius: 50%;
    opacity: 1 !important;
}
#bannerPictureMask{
    transition: transform .4s; /* Animation */
    // -webkit-mask-image: linear-gradient(black, transparent 93%);
    // mask-image: linear-gradient(black, transparent 93%);
}
#bannerPictureMask:hover{
    transform: scale(1.175); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.cube {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #D7D4E4;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
    border-color: #FFF;
}
.cube:nth-child(1) {
    animation-delay: 10s;
    left: 70vw;
    top: 80vh;
}
.cube:nth-child(2) {
    animation-delay: 2s;
    left: 20vw;
    top: 20vh;
}
.cube:nth-child(3) {
    animation-delay: 4s;
    left: 55vw;
    top: 35vh;
}
.cube:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
}
.cube:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 65vh;
}
@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
    }
    @keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

/* ========== */
/* Prestation */
/* ========== */
#pr_id_7{
    width: 100% !important;
}
th, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link, .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
    background-color: #317F99 !important;
    color: white !important;
}
.p-accordion .p-accordion-header .p-accordion-header-link, .p-accordion .p-accordion-header .p-accordion-header-link:hover{
    font-weight: 450 !important;
    border: 1px solid #0A5974 !important;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    box-shadow: 0 0 0 0.2rem #0A5974 !important;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background-color: #0A5974 !important;
}
.p-accordion-content{
    color: #317F99 !important;
    background-color: transparent !important;
    border: none !important;
}
tr{
    color: white !important;
    background-color: transparent !important;
}
.p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th, .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    border: none !important;
}
.p-datatable .p-datatable-tbody > tr{
    color: #317F99 !important; 
}

@media screen and (max-width: 500px) {
    .prestationsContent{
        display: block;
        width: 80%;
    }
    #prestationsLeft {
      width: 100%;
      margin-bottom: 5%;
    }
    .prestationsRight {
        width: 100%;
    }
}

/* ======= */
/* Contact */
/* ======= */
.signupSection {
    // background: url(<imgSource>);
    // background-repeat: no-repeat;
    display: inline-block;
    display: flex;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    
    // box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;

    text-align: center;
    color: white;
}
.info {
    width: 45%;

    border-radius: 17px;
    background: rgba(3, 69, 91, 0.5);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, rgba(10, 89, 116, 0.5), rgba(10, 89, 116, 0.5), rgba(3, 69, 91, 0.5));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, rgba(10, 89, 116, 0.5), rgba(10, 89, 116, 0.5), rgba(3, 69, 91, 0.5)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;

    padding: 30px 0;
    margin-right: 5%;
    h2 {
        padding-top: 30px;
        color: #FFB572;
        text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
    }
}
.contact {
    border-radius: 17px;

    width: 70%;
    padding: 30px 0;
    background: rgba(3, 69, 91, 0.85);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, rgba(49, 127, 153, 0.5), rgba(20, 115, 146, 0.5), rgba(10, 89, 116, 0.5));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, rgba(49, 127, 153, 0.5), rgba(20, 115, 146, 0.5), rgba(10, 89, 116, 0.5)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.175) 0px 7px 29px 0px;
    h2 {
        padding-top: 30px;
        color: #FFB572;
        text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.2);
    }
}
.contactForm{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.contactEditor{
    margin-bottom: 6%;
}
#contactSendBtn{
    width: 50%;
}
.contactInfoCol{
    height: 100%;
    margin-top: 13%;
}
.contactInfoCard{
    display: inline-block;
    width: 100%;

    justify-content: center;
    
    padding-top: 2%;
    padding-bottom: 2%;

    text-decoration: none;
    .mr-2{
        transition: 0.5s ease;
    }
    .mr-2:hover{
        background-color: white !important;
        color: #FFB572 !important;
        transition: 0.5s ease;
    }
}
.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix{
    color: white !important;
}
.inline{
    transition: 0.5s ease;
}
.inline:hover{
    color: #FFB572 !important;
    transition: 0.5s ease;
}

.contentEmailContent{
    height: 20vh;
    width: 30vw;

    background-color: transparent;
    color: white;
    border: 1px solid #ced4da;
    border-radius: 3px;
}
.contentEmailContent:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #ffe69c;
    border-color: #FFC107;
}
.contentEmailContent:hover{
    border-color: #FFC107;
}

@media screen and (max-width: 500px) {
    .signupSection{
        display: block;
        width: 80%;
    }
    .info {
      width: 100%;
      margin-bottom: 5%;
    }
    .contact {
        width: 100%;
    }
    .contactInfoCol{
        margin-top: 5%;
    }
    .contentEmailContent{
        width: 100%;
    }
}