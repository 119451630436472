.footer {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 30px 30px 20px 30px;
  color: #ffffff;
  bottom: 0;

  
  /* background: #317F99;  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to bottom, #03455B, #0A5974, #147392);  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to bottom, #03455B, #0A5974, #147392); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
}

hr.solid {
  /* border-radius: 5px; */
  border-top: 2px solid #FFB572;
  opacity: 1;

  width: 85%;

  margin-right: auto;
  margin-left: auto;
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 25% !important;
  margin-left: 25% !important;
  margin-bottom: 2em;
}

.footer__addr h5 {
  margin-top: 1.3em;
  font-size: 20px;
}

#customFooterText{
  font-size: 3vw;
  margin-top: 3%;
  margin-left: 13% !important;
}

.nav__title {
  font-size: 24px;
  color: #FFB572;
  text-shadow: 1px 3px 3px rgba(30, 30, 30, 0.25);
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
    flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: white;
  font-size: 17px;
  transition: 0.5s ease;
}
.nav__ul a:hover{
  color: #FFB572 !important;
  transition: 0.5s ease;
}

.legal {
  color: white;
  width: 100%;
  text-align: center;
}

.legalRights{
  font-size: 0.65vw;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }
  
  .nav__item--extra {
    flex-grow: 2;
  }
  
  .footer__addr {
    flex: 1 0px;
  }
  
  .footer__nav {
    flex: 2 0px;
  }
}

@media screen and (max-width: 500px) {
  .footer__nav{
    text-align: center;
  }
  .nav__title{
    font-size: 16px;
  }
  .legal{
    margin-top: 5%;
  }
  .legalRights{
    font-size: 10px;
  }
}