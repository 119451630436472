.passwordContainer{
    margin-top: 13vh;
    margin-bottom: 13vh;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
}

#passwordButton{
    margin-top: 4vh;
}
