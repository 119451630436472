.loaderContainer{
    background-color: rgba(255, 255, 255, 1);
    height: 100vh;
    width: 100%;

    text-align: center;
}

.loaderResize{
    width: 40%;
    height: auto;

}