.scrollContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 255;
}

.scrollContainer .scrollIndicator{
    height: 100%;
    background: #F59A48 !important;
    z-index: 255;
}