:root {
  --surface-color: rgba(255, 255, 255,1);
  --curve: 40;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 0px !important;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px) !important;
  overflow: hidden;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.125) 0px 7px 29px 0px;
  transition: 1s;
}
.card:hover{
    transform: scale(1.15) translateZ(0);
    transition: 1.25s;
}

.card__image {      
  width: 120%;
  height: auto;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}
.card:hover .card__overlay {
  transform: translateY(0);
  transition: 1s;
}
.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}
.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}
.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       
.card:hover .card__header {
  transform: translateY(0);
  transition: 0.85s;
}
/* .card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
} */
.card__title {
  font-size: 1.5vw;
  margin: 0 0 .3em;
  color: #317F99;
  font-family: 'Pacifico', cursive;
  text-shadow: 1px 3px 3px rgb(30 30 30 / 10%);
}
.card__status {
  font-size: 1vw;
  color: #9d9d9d;
}
.card__description {
  padding: 0 2em 2em;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 1vw;
  overflow: hidden;
  color: #9d9d9d;
  /* color: #5399B0; */
}

.launchPack{
  width: 100%;
}
.customCard {
  width: 30%;
  margin-right: auto;
  margin-left: auto;
  list-style-type: none;
}
.customCardDescription{
  font-size: 1vw;
}
.prestationSubTitle{
  color: #317F99;
  font-family: 'Pacifico', cursive;
  text-shadow: 1px 3px 3px rgb(30 30 30 / 10%);
  font-size: 2.5vw;
  
  margin-left: 6%;
}
.prestationSubTitleRight{
  color: #317F99;
  font-family: 'Pacifico', cursive;
  text-shadow: 1px 3px 3px rgb(30 30 30 / 10%);
  font-size: 2.5vw;

  display: flex;
  justify-content: right;
  margin-right: 6%;
}
.prestationSubTitleCenter{
  color: #317F99;
  font-family: 'Pacifico', cursive;
  text-shadow: 1px 3px 3px rgb(30 30 30 / 10%);
  font-size: 2.5vw;

  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .customCard {
    width: 100% !important;
  }
}