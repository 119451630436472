.registerContainer{
    margin-bottom: 4vh;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
}
.registerBackground{
    padding-top: 9vh;
    background-color: #03455B;
}
#registerSpace{
    margin-top: 12%;
}
#registerSendBtn{
    margin-top: 8% !important;
}

.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix{
    color: #6c757d;
}