.scroll-to-top {
    position: fixed;
    bottom: 0.5rem;
    right: 1.5rem;
    animation: fadeIn 700ms ease-in-out 2s both;
    cursor: pointer;
    color: #FFB572;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.20); 
    transition: 0.3s;
    z-index: 1;
}

.scroll-to-top:hover{
    color: #F59A48;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}